import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import Seo from "../components/App/Seo"
import { Col, Container, Row } from "react-bootstrap"
import { StaticImage } from "gatsby-plugin-image"
import WichtigeMerkmale from "../components/ProductDetails/WichtigeMerkmale"
import keyfacts from "../assets/images/logimat-keyfacts.png"
import { Link } from "gatsby"
import { LogisticYears } from "../components/AboutUs/LogisticYears"

const Logimat2023Index = () => {
  return (
    <Layout>
      <Seo title={"LOGENTIS GmbH - LogiMAT 2024"}
           description={"LOGENTIS bei der LogiMAT 2024. Kurzinformationen, Downloads und weiteres hier!"}
           image={"/images/logimat-2024-header.png"}>
        <script type="application/ld+json">{`
                {
                  "@context": "https://schema.org",
                  "@type": "BreadcrumbList",
                  "itemListElement": [{
                    "@type": "ListItem",
                    "position": 1,
                    "name": "Home",
                    "item": "https://logentis.de"
                  },{
                    "@type": "ListItem",
                    "position": 2,
                    "name": "Logimat 2024",
                    "item": "https://logentis.de/logimat-2024/"
                  },{
                    "@type": "ListItem",
                    "position": 3,
                    "name": "Versandsoftware",
                    "item": "https://logentis.de/versandsoftware/"
                  },{
                    "@type": "ListItem",
                    "position": 4,
                    "name": "Lagerverwaltung",
                    "item": "https://logentis.de/lagerverwaltung/"
                  }]
                }
                `}
        </script>
      </Seo>
      <Navbar />

      <section className="services-details-area ptb-100">
        <Container className="mt-5">
          <Row>
            <Col lg={8} md={12}>
              <div className="services-details-image">
                <StaticImage
                  src="../assets/images/logimat-2024-header.png"
                  alt="Der Logentis Stand bei der Logimat 2024"
                  placeholder="blurred"
                  width={900}
                />
              </div>

              <div className="services-details-desc">
                <span className="sub-title" data-nosnippet="true">Infos, Downloads und mehr</span>
                <h1 className={"pt-3"}>LogiMAT 2024</h1>
                <p>
                  Wir begrüßen Sie herzlich anlässlich der <b>LogiMAT 2024</b> in Stuttgart. Wir entwickeln seit nunmehr
                  <br /> <LogisticYears /> Jahren serverbasierte Software für die
                  Logistik und haben mit NETVERSYS und NETSTORSYS zwei erfolgreiche Produkte für die Intralogistik an
                  den Markt gebracht.
                </p>
                <p>
                  <Link to={"/lagerverwaltung/"}>NETSTORSYS</Link> organisiert und verwaltet als hochmodernes Warehouse
                  Management System ihr manuelles Warenlager, <Link to={"/versandsoftware/"}>NETVERSYS</Link> macht
                  den Schritt über die Rampe und kümmert sich als Multi-Carrier-Versandsystem um den schnellen und
                  effizienten Versand ihrer Waren mit den verschiedensten Transportdienstleistern. Beiden System gemein
                  ist die zentrale Architektur und der Einsatz modernster Technologien, ebenso vereinen beide Lösungen
                  unsere Philosophie, äußerst einfach bedienbar zu sein.
                </p>

                <p>
                  Die hier zum Download angebotenen Flyer sollen Ihnen einen ersten Überblick verschaffen,
                  klicken Sie sich auch gern durch die Produktbereiche unserer Webseite für weitere Informationen.
                </p>

                <p>
                  Sie haben noch weitere Fragen zu unseren Produkten und Leistungen? Oder Sie möchten uns und unsere
                  Produkte kennenlernen? Dann melden Sie sich gern telefonisch
                  unter <a href="tel:+49541587580">+49 541 587 580</a>, oder
                  per E-Mail: <a href="mailto:info@logentis.de">info@logentis.de</a>.
                  Oder, falls Sie noch auf der Messe unterwegs sind, schauen Sie einfach nochmal bei uns
                  in <b>Halle 1, Stand C20</b> vorbei.

                  <br />
                  <br />

                  Wir freuen uns auf Sie!
                </p>

                <WichtigeMerkmale
                  title={"Key Facts"}
                  items={[
                    "Cloudbasiert oder On-Premise",
                    "Multi-Carrier Versand",
                    "manuelles Lager",
                    "Least-Cost Option",
                    "Customer-Choice"
                  ]}
                  image={<img src={keyfacts}
                              alt="Key Facts - Logentis Software"
                              className={"ml-lg-4 ml-0"} />}
                />
              </div>
            </Col>

            <Col lg={4} md={12}>
              <div className="services-details-info">
                <div>
                  <div className={"download-file"}>
                    <h3>NETVERSYS</h3>

                    <ul>
                      <li>
                        <a href="/dl/netversys_flyer.pdf">
                          Produktflyer <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                      <li>
                        <a href="/dl/casestudy_aswo.pdf">
                          Case Study: ASWO International <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                      <li>
                        <a href="/dl/casestudy_gcgruppe.pdf">
                          Case Study: GC Gruppe <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="services-details-info">
                <div>
                  <div className={"download-file"}>
                    <h3>NETSTORSYS</h3>

                    <ul>
                      <li>
                        <a href="/dl/netstorsys_flyer.pdf">
                          Produktflyer <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                      <li>
                        <a href="/dl/netstorsys_casestudy_thomasphilipps.pdf">
                          Case Study: Thomas Philipps <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                      <li>
                        <a href="/dl/netstorsys_casestudy_plenge.pdf">
                          Case Study: Plenge GmbH <i className="bx bxs-file-pdf" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className={"services-area pt-4 pb-70 bg-f1f8fb"}>
        <Container>
          <span className="mb-2 text-primary"><i className={"bx bxs-star"} /> Presentation</span>
          <h2>Messevideo</h2>
          <Row className={"mt-5"}>
            <Col xs={12} className={"justify-content-center d-flex px-4"}>
              <video
                width={"100%"}
                autoPlay={true}
                loop={true}
                controls={true}
                aria-label={"Messevideo - Presentation"}>
                <source src="https://downloads.logentis.net/images/logimat_presentation_2024.webm" type="video/webm"/>
              </video>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </Layout>
  )
}

export default Logimat2023Index
